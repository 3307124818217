import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LoadingSpinnerOverlay } from '../Spinner';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { getNfts } from '../api/getNfts';
import { TabSelector } from '../components/TabSelector';
import { isPWAEnabled } from '../lib/isPWAEnabled';
import { isMobile } from 'react-device-detect';
import { SugorokuErrorDialog } from '../components/SugorokuErrorDialog';
import PlayerList from '../components/PlayerList';
import Nft from '../types/nftType';
import {
    SugorokuNFTCard,
    SugorokuNFTCardInfo,
} from '../components/SugorokuNFTCard';
import { useNavigate } from 'react-router-dom';
import { PATH_MAPPING } from '../constants/pathMapping';
import { EVENT_TYPE } from '../constants/eventType';
import { SugorokuNftDetailContent } from '../components/SugorokuNftDetailContent';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${() => (isPWAEnabled() && isMobile ? '20px' : '40px')};
`;

const Title = styled.h2`
    margin: 40px 0 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`;

const Content = styled.div`
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    padding: 0 20px 20px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${() => (isPWAEnabled() && isMobile ? '20px 20px' : '30px 20px')};
    place-items: center center;

    @media (width < 375px) {
        grid-template-columns: 1fr;
    }
`;

const EmptyMessage = styled.p`
    max-width: 400px;
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
`;

const TitleContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const ArrowContainer = styled.div`
    max-width: 400px;
    padding: 0 20px;
    margin: 0 auto;
`;

const ArrowText = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
`;

const SugorokuRewardList: React.FC = () => {
    const [isPlayerListActive, setIsPlayerListActive] = useState(true);
    const [nfts, setNfts] = useState<Nft[]>([]);
    const [loadingState, setLoadingState] = useState<
        'loading' | 'success' | 'error'
    >('loading');
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNfts = async () => {
            try {
                setLoadingState('loading');
                const response = await getNfts(EVENT_TYPE.SUGOROKU);
                setNfts(response);
                setLoadingState('success');
            } catch (error) {
                console.error('Failed to fetch NFTs:', error);
                setLoadingState('error');
                setHasError(true);
            }
        };

        fetchNfts();
        ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_ATTENDANCE_HISTORY_PAGE);
    }, []);

    const handleArrowClick = () => {
        navigate(PATH_MAPPING.SUGOROKU);
    };

    if (loadingState === 'loading') {
        return <LoadingSpinnerOverlay />;
    }

    return (
        <div>
            {hasError && <SugorokuErrorDialog />}
            {!nfts ? (
                <LoadingSpinnerOverlay />
            ) : (
                <>
                    <TitleContainer>
                        <Title>
                            2024シーズン
                            <br />
                            すごろく特典一覧
                        </Title>
                    </TitleContainer>
                    <ArrowContainer>
                        <ArrowText onClick={handleArrowClick}>
                            ← すごろくゲームに戻る
                        </ArrowText>
                    </ArrowContainer>
                    <TabSelector
                        isFlipped={isPlayerListActive}
                        onFlipToPlayers={() => setIsPlayerListActive(true)}
                        onFlipToRewards={() => setIsPlayerListActive(false)}
                    />
                    {isPlayerListActive ? (
                        <PlayerList />
                    ) : (
                        <Container>
                            {!nfts || nfts.length === 0 ? (
                                <EmptyMessage>NFTがありません。</EmptyMessage>
                            ) : nfts.length === 1 ? (
                                <Content>
                                    <SugorokuNftDetailContent
                                        eventId={Number(nfts[0].event.id)}
                                    />
                                </Content>
                            ) : (
                                <Content>
                                    <Grid>
                                        {nfts.map((nft, index) => (
                                            <div key={index}>
                                                <SugorokuNFTCard
                                                    nft={nft}
                                                    isFlipped={
                                                        isPlayerListActive
                                                    }
                                                />
                                                <SugorokuNFTCardInfo
                                                    nft={nft}
                                                />
                                            </div>
                                        ))}
                                    </Grid>
                                </Content>
                            )}
                        </Container>
                    )}
                </>
            )}
        </div>
    );
};

export default SugorokuRewardList;
