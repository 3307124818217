import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import downloadIcon from '../assets/images/icon_dl.svg';
import noImage from '../assets/images/noimage.jpg';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { NftDetail } from '../types/nftDetailType';
import { getNftDetail } from '../api/getNftDetail';
import { LoadingSpinnerOverlay } from '../Spinner';

const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ImageContainer = styled.div`
    margin-top: 10px;
`;

const NftImageContainer = styled.div<{ itemProp?: string }>`
    height: 491px;
    background-image: url(${(props) => props.itemProp});
    filter: drop-shadow(3px 3px 6px rgba(0 0 0/80%));
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media (width <= 400px) {
        max-width: 90vw;
        max-height: calc(90vw * 491 / 360);
    }
`;

const RoundButton = styled.div<{ $isPressed: boolean; $isVisible: boolean }>`
    position: fixed;
    right: calc(50% - 185px);
    bottom: 95px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
    cursor: ${({ $isVisible }) => ($isVisible ? 'pointer' : 'default')};
    background-color: #d10411;
    border: none;
    border-radius: 50%;
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    transition: opacity 0.2s ease;

    &::before,
    &::after {
        position: absolute;
        width: 15px;
        height: ${({ $isPressed }) => ($isPressed ? '2px' : '1.8px')};
        content: '';
        background: #ffffff;
        transition:
            transform 0.1s ease,
            height 0.1s ease;
    }

    &::before {
        transform: rotate(${({ $isPressed }) => ($isPressed ? '0' : '90')}deg);
    }

    @media (width <= 400px) {
        right: 10px;
    }
`;

const ChildButton = styled.button`
    position: absolute;
    width: 55px;
    height: 55px;
    border: none;
    border-radius: 50%;
`;

const TopChildButton = styled(ChildButton)`
    top: -65px;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    animation: jump 0.4s ease-in-out;
    animation-fill-mode: forwards;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 31.98px;
        height: 31.04px;
        content: '';
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: translate(-50%, -50%);
    }
`;

const LeftChildButton = styled(ChildButton)`
    top: 0;
    left: -65px;
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    animation: jump 0.6s ease-in-out;
    animation-fill-mode: forwards;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 34px;
        height: 23.48px;
        content: '';
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: translate(-50%, -50%);
    }
`;

const LinkSpaceHolder = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 24px;
    margin-top: 10px;
`;

const LinkContainer = styled.div<{ $isVisible: boolean }>`
    display: flex;
    justify-content: center;
    max-height: ${({ $isVisible }) => ($isVisible ? '50px' : '0px')};
    overflow: hidden;
    visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
    opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};
    transition:
        opacity 0.6s ease,
        max-height 0.6s ease,
        visibility 0.8s ease-in-out;

    a {
        display: block;
        font-size: 8px;
        font-weight: 500;
        color: #848487;
        text-decoration: none;
        word-break: break-all;
        white-space: normal;
    }
`;

const DownloadLink = styled.span`
    position: absolute;
    top: 24px;
    right: 0;
    display: flex;
    font-size: 14px;
    color: #141313;
    text-decoration: underline;
    cursor: pointer;
`;

const DownloadIcon = styled.img`
    margin-left: 9px;
`;

const NftDescriptionDisclosure = styled.details`
    padding: 1rem 1rem 0;
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #141313;
    white-space: pre-wrap;
    cursor: pointer;
    border-radius: 4px;

    summary {
        padding: 0.5em;
        margin: -0.5em -0.5em 0;
        font-weight: bold;
        cursor: pointer;
    }

    &[open] {
        padding: 1rem;
        line-height: 1.5;
        background-color: #ffffff;

        summary {
            margin-bottom: 0.5em;
        }
    }
`;

interface SugorokuNftDetailContentProps {
    eventId: number;
}

export const SugorokuNftDetailContent: React.FC<
    SugorokuNftDetailContentProps
> = ({ eventId }) => {
    const [nft, setNft] = useState<NftDetail | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isPressed, setIsPressed] = useState(false);
    const [scrollToTab, setScrollToTab] = useState<number | null>(null);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const { inView: fakeContainerInView } = useInView({
        initialInView: true,
    });
    const photoGalleryRef = useRef<HTMLDivElement>(null);
    const diaryEditorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchNftDetail = async () => {
            if (!eventId) {
                setError('NFT IDが指定されていません');
                setIsLoading(false);
                return;
            }
            try {
                setIsLoading(true);
                setError(null);
                const detail = await getNftDetail(eventId);
                setNft(detail);
            } catch (error) {
                console.error('Failed to fetch NFT detail:', error);
                setError('NFTの詳細情報の取得に失敗しました');
            } finally {
                setIsLoading(false);
            }
        };

        fetchNftDetail();
    }, [eventId]);

    useEffect(() => {
        if (scrollToTab === 0 && photoGalleryRef.current) {
            photoGalleryRef.current.scrollIntoView({ behavior: 'smooth' });
            setScrollToTab(null);
        } else if (scrollToTab === 1 && diaryEditorRef.current) {
            diaryEditorRef.current.scrollIntoView({ behavior: 'smooth' });
            setScrollToTab(null);
        }
    }, [scrollToTab]);

    useEffect(() => {
        setIsButtonVisible(!fakeContainerInView);
    }, [fakeContainerInView]);

    useEffect(() => {
        if (fakeContainerInView) {
            setIsPressed(false);
        }
    }, [fakeContainerInView]);

    const handleChildButtonClick = (tabIndex: number) => {
        setScrollToTab(tabIndex);
    };

    const handleButtonClick = () => {
        setIsPressed(!isPressed);
    };

    const handleCardDownload = async (cardImageUrl: string) => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_CARD_DOWNLOAD_LINK);
        const response = await fetch(cardImageUrl);
        const blob = await response.blob();
        const file = new File([blob], `nft.png`, { type: 'image/png' });
        if (navigator.share) {
            navigator.share({
                files: [file],
                title: 'NFTカードダウンロード',
                text: 'NFTカードをダウンロードします',
            });
        } else {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.download = file.name;
            a.click();
        }
    };

    if (isLoading) {
        return <LoadingSpinnerOverlay />;
    }

    if (error || !nft) {
        return <div>{error || 'NFTの詳細情報が見つかりません'}</div>;
    }

    return (
        <DetailContainer>
            <ImageContainer>
                <NftImageContainer itemProp={nft.imageUrl ?? noImage} />
                {nft.event.canPostTrajectory && (
                    <RoundButton
                        onClick={handleButtonClick}
                        $isPressed={isPressed}
                        $isVisible={isButtonVisible}
                    >
                        {isPressed && (
                            <>
                                <TopChildButton
                                    onClick={() => handleChildButtonClick(1)}
                                />
                                <LeftChildButton
                                    onClick={() => handleChildButtonClick(0)}
                                />
                            </>
                        )}
                    </RoundButton>
                )}
            </ImageContainer>
            <LinkSpaceHolder>
                <LinkContainer $isVisible={true}>
                    <a
                        href={nft.nftAddress}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {nft.nftAddress}
                    </a>
                </LinkContainer>
                {nft.imageUrl && (
                    <DownloadLink
                        onClick={() => handleCardDownload(nft.imageUrl)}
                    >
                        カードDL
                        <DownloadIcon
                            src={downloadIcon}
                            alt="ダウンロードアイコン"
                        />
                    </DownloadLink>
                )}
            </LinkSpaceHolder>
            {nft.description && (
                <NftDescriptionDisclosure>
                    <summary>NFT詳細</summary>
                    {nft.description}
                </NftDescriptionDisclosure>
            )}
        </DetailContainer>
    );
};
