import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PlayerList from './PlayerList';
import { getClubPlayer } from '../api/getClubPlayer';
import ClubPlayer from '../types/clubPlayer';
import { SugorokuErrorDialog } from './SugorokuErrorDialog';
import DateFormatter from '../lib/dateFormatter';

import { LoadingSpinnerOverlay } from '../Spinner';
import CloseIconButton from './CloseIconButton';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';

/**
 * ページ全体のコンテナ
 */
const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    max-width: 400px;
    min-height: calc(100dvh - 48px - 82px);
    padding: 40px 20px 20px;
    margin: 20px auto 0;
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
`;

/**
 * ページタイトル
 */
const TitleAndCloseButtonContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

/**
 * 閉じるボタンのコンテナ
 */
const CloseButtonContainer = styled.div`
    position: absolute;
    top: -8px;
    right: 0;
`;

/**
 * ページタイトルの選手名の部分
 */
const TitlePlayerName = styled.span`
    position: absolute;
    left: calc(50%);
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    word-break: keep-all;
    transform: translateX(-50%);
`;

/**
 * 選手の写真と情報のコンテナ
 */
const PlayerBiographyContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    margin-top: 10px;
`;

/**
 * 選手アイコン
 */
const PlayerIcon = styled.img`
    max-width: 198px;
    aspect-ratio: 198 / 251;
    border: 2px solid black;
    border-radius: 10px;
    object-fit: cover;
`;

/**
 * 選手情報のラッパー
 */
const PlayerInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: start;
    justify-content: start;
`;

/**
 * 選手情報 - ポジションのラッパー
 */
const PlayerInfoPositionWrapper = styled.span`
    display: grid;
    font-weight: 900;
`;

/**
 * 選手情報 - ポジション
 */
const PlayerInfoPositionText = styled.span`
    font-size: 14px;
`;

/**
 * 選手情報 - 英名
 */
const PlayerInfoEnglishNameText = styled.span`
    font-size: 18px;
`;

/**
 * 選手情報のコンテナ
 */
const PlayerInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    font-size: 14px;
`;

/**
 * 選手情報のラベルとテキストのコンテナ
 */
const PlayerInfoContent = styled.div`
    display: grid;
    row-gap: 5px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
`;

/**
 * 選手情報のラベル
 */
const PlayerInfoLabel = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: #777777;
`;

/**
 * 選手情報のテキスト
 */
const PlayerInfoText = styled.div`
    font-size: 12px;
    font-weight: bold;
`;

/**
 * ページ下部のコンテナ（㊙情報の部分）
 */
const BelowContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background-color: #f7f7f7;

    &::before {
        position: absolute;
        top: -18px;
        right: -4px;
        width: 0;
        height: 0;
        content: '';
        border-top: 24px solid transparent;
        border-right: 24px solid white;
        border-bottom: 24px solid transparent;
        rotate: 135deg;
    }

    &::after {
        position: absolute;
        top: -10px;
        right: 0;
        width: 0;
        height: 0;
        content: '';
        border-top: 20px solid transparent;
        border-right: 30px solid transparent;
        border-bottom: 20px solid transparent;
        box-shadow: -2px 1px 2px #0000001a;
    }
`;

/**
 * ㊙情報コンテナ
 */
const PlayerContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    min-width: 100%;
`;

/**
 * ㊙情報のテープっぽい部分
 */
const PlayerContentTape = styled.div`
    position: relative;
    width: fit-content;
    padding: 10px;
    margin-top: 16px;
    background-color: #e40514;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 5px; /* ギザギザの幅 */
        content: '';
        background:
            linear-gradient(200deg, #f7f7f7 3px, transparent 0) 0 3px,
            linear-gradient(315deg, #f7f7f7 3px, transparent 0) 0 3px;
        background-size: 7px 3px;
    }

    &::before {
        left: -1px;
        rotate: 180deg;
    }

    &::after {
        right: -1px;
    }

    rotate: -3deg;
`;

/**
 * ㊙情報タイトル
 */
const PlayerContentTitle = styled.span`
    display: inline-block;
    width: fit-content;
    max-width: 220px;
    padding: 0 13px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    word-break: keep-all;
`;

/**
 * ㊙情報のコンテンツ
 */
const PlayerContent = styled.div`
    margin: 0 20px;
    border-top: 1px solid #e0e0e0;

    & hr {
        border-top: 1px solid #e0e0e0;
    }

    & img,
    video {
        width: 100%;
    }
`;

interface PlayerDetailProps {
    code: string;
}

const PlayerDetail: React.FC<PlayerDetailProps> = ({ code }) => {
    const [showPlayerListScreen, setShowPlayerListScreen] = useState(false);
    const [playerData, setPlayerData] = useState<ClubPlayer | null>(null);
    const [hasError, setHasError] = useState(false);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_SUGOROKU_PLAYER_DETAIL_PAGE);
        }
        setInitialized(true);
    }, [initialized]);

    useEffect(() => {
        const fetchPlayerData = async () => {
            try {
                const playerData = await getClubPlayer(code);
                setPlayerData(playerData);
            } catch (error) {
                setHasError(true);
            }
        };
        fetchPlayerData();
    }, [code]);

    const handleCloseButtonClick = () => {
        setShowPlayerListScreen(true);
    };

    return (
        <>
            {hasError && <SugorokuErrorDialog />}
            {showPlayerListScreen ? (
                <PlayerList />
            ) : !playerData ? (
                <LoadingSpinnerOverlay />
            ) : (
                <Container>
                    <TitleAndCloseButtonContainer>
                        <TitlePlayerName>{playerData.name}</TitlePlayerName>
                        <CloseButtonContainer>
                            <CloseIconButton onClick={handleCloseButtonClick} />
                        </CloseButtonContainer>
                    </TitleAndCloseButtonContainer>
                    <PlayerBiographyContainer>
                        <PlayerIcon
                            src={playerData.imageIconUrl}
                            alt={playerData.name}
                        />
                        <PlayerInfoWrapper>
                            <PlayerInfoPositionWrapper>
                                <PlayerInfoPositionText>
                                    {playerData.position}
                                </PlayerInfoPositionText>
                                <PlayerInfoEnglishNameText>
                                    {playerData.englishName}
                                </PlayerInfoEnglishNameText>
                            </PlayerInfoPositionWrapper>
                            <PlayerInfoContainer>
                                <PlayerInfoContent>
                                    <PlayerInfoLabel>生年月日</PlayerInfoLabel>
                                    <PlayerInfoText>
                                        {DateFormatter.formatDate(
                                            playerData.birthday,
                                            false,
                                            false
                                        )}
                                    </PlayerInfoText>
                                </PlayerInfoContent>
                                <PlayerInfoContent>
                                    <PlayerInfoLabel>出身地</PlayerInfoLabel>
                                    <PlayerInfoText>
                                        {playerData.hometown}
                                    </PlayerInfoText>
                                </PlayerInfoContent>
                                <PlayerInfoContent>
                                    <PlayerInfoLabel>身長/体重</PlayerInfoLabel>
                                    <PlayerInfoText>
                                        {playerData.height}cm /{' '}
                                        {playerData.weight}kg
                                    </PlayerInfoText>
                                </PlayerInfoContent>
                                <PlayerInfoContent>
                                    <PlayerInfoLabel>血液型</PlayerInfoLabel>
                                    <PlayerInfoText>
                                        {playerData.bloodType !== ''
                                            ? `${playerData.bloodType}型`
                                            : '-'}
                                    </PlayerInfoText>
                                </PlayerInfoContent>
                            </PlayerInfoContainer>
                        </PlayerInfoWrapper>
                    </PlayerBiographyContainer>
                    {playerData.content[0].content !== '' ? (
                        <BelowContainer>
                            {/* TODO 一旦最初の1件だけにする */}
                            <PlayerContentContainer>
                                <PlayerContentTape>
                                    <PlayerContentTitle>
                                        {playerData.presentationName}の ㊙情報
                                    </PlayerContentTitle>
                                </PlayerContentTape>
                                <PlayerContent>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: playerData.content[0]
                                                .content,
                                        }}
                                    />
                                </PlayerContent>
                            </PlayerContentContainer>
                        </BelowContainer>
                    ) : null}
                </Container>
            )}
        </>
    );
};

export default PlayerDetail;
