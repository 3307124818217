import React from 'react';
import styled from 'styled-components';
import { PlayerIcon, RewardIcon } from './IconComponents';

const TabContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Tab = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    padding: 3px;
    font-size: 14px;
    font-weight: ${(props) => (props.disabled ? 'bold' : 'normal')};
    color: ${(props) => (props.disabled ? 'black' : '#7D7D7D')};
    text-align: center;
    cursor: pointer;
`;

const Separator = styled.div`
    margin: 0 40px;
    color: #aaaaaa;
`;

type TabSelectorProps = {
    isFlipped: boolean;
    onFlipToPlayers: () => void;
    onFlipToRewards: () => void;
};

export const TabSelector: React.FC<TabSelectorProps> = ({
    isFlipped,
    onFlipToPlayers,
    onFlipToRewards,
}) => {
    return (
        <TabContainer>
            <Tab onClick={onFlipToPlayers} disabled={isFlipped}>
                選手プチ情報
                <PlayerIcon />
            </Tab>
            <Separator>|</Separator>
            <Tab onClick={onFlipToRewards} disabled={!isFlipped}>
                ゴール特典NFT
                <RewardIcon />
            </Tab>
        </TabContainer>
    );
};
